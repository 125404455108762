import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';

const QuoteForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [breifdesc, setbreifdesc] = useState('');

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handlebreifdesc = (e) => {
    setbreifdesc(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(name === '' || name === null){
        toast.error('Please enter your name!',
        {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
        return false;
    }
    if(phone === '' || phone === null){
        toast.error('Please enter your phone number!',
        {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
        return false;
    }
    try {
      const response = await fetch('https://formspree.io/mwkgywdq', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, phone,breifdesc }),
      });
      if (response.ok) {
        toast.success('Succesfully Submitted!',
        {
          icon: '👏',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
        // Reset form fields after successful submission
        setName('');
        setEmail('');
        setPhone('');
        setbreifdesc('')
      } else {
        toast.error('Something went wrong!',
        {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
      }
    } catch (error) {
        toast.error('Something went wrong!',
        {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
    }
  };
  return (
    <div className="container" style={{paddingBottom: "100px"}}>
        <div><Toaster/></div>
      <h2 className="mt-5 mb-4">Request a Quote</h2>
      <p>Let's chat over for your next finishing project. Just fill the form and we will get back to you real soon.</p>
      <form  onSubmit={handleSubmit} method="POST">
        <div className="mb-3">
          <label htmlFor="name" className="form-label">Name:<span style={{color:"red"}}>*</span></label>
          <input type="text" id="name" name='name' className="form-control" value={name} onChange={handleNameChange} />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email:</label>
          <input type="text" id="email" name='email' className="form-control" value={email} onChange={handleEmailChange} />
        </div>
        <div className="mb-3">
        <label htmlFor="email" className="form-label">Phone:<span style={{color:"red"}}>*</span></label>
          <input type="tel" id="phone" name='phone' className="form-control" value={phone} onChange={handlePhoneChange} />
        </div>
        <div className="mb-3">
        <label htmlFor="email" className="form-label">Brief Description:</label>
          <textarea id="breifdesc" name='breifdesc' className="form-control" value={breifdesc} onChange={handlebreifdesc} />
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
    </div>
  );
};

export default QuoteForm;
