import React from 'react'

const WhoWeAre = () => {
  return (
    <div className='row' style={{display:"flex",justifyContent:"center"}}>
      <div className="col-lg-8" style={{paddingTop:"30px"}}>
       <h3>Welcome to Captain Finishing! We're a small team passionate about bringing quality interior and exterior finishing solutions to our clients.</h3>
       <h2 style={{paddingTop:"50px"}}>Meet Our Team</h2>
      </div>
    </div>
  )
}

export default WhoWeAre