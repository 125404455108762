import "./App.css";
import QuoteForm from "./Components/QuoteForm";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Layout from "./Components/Layout";
import WhatWeDo from "./Components/WhatWeDo";
import WhoWeAre from "./Components/WhoWeAre";
import ContactUs from "./Components/ContactUs";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="quote" element={<QuoteForm />} />
          <Route path="what-we-do" element={<WhatWeDo />} />
          <Route path="who-we-are" element={<WhoWeAre />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
