import React from 'react';

function ContactUs() {
  return (
    <div className="contact-us-container" style={{width:"100vw",overflowX:"hidden"}}>
      <div className="contact-info">
        <h2>Get in Touch with the Captain of Finishing!</h2>
        <p>Welcome aboard! At Captain Finishing, we're passionate about transforming ordinary wood into extraordinary pieces of art.</p>
        <div className="info-item">
          <p>Have a question or a project in mind? Contact <b>Arsh</b> at <b>(236) 818 2065</b> or <b>Gurdev</b> at <b>(778) 242 9375</b>. Our team is dedicated to providing personalized service and expert advice to bring your woodwork dreams to life.</p>
        </div>
        <div className="info-item">
          <p>Shoot us an email at captainfinishing@gmail.com to kickstart your wood finishing journey. Whether it's a custom project, a restoration job, or just a friendly chat about wood, we're here to listen!</p>
        </div>
      </div>
      <div className="business-hours">
        <h2>Business Hours</h2>
        <p>Reach outto us Monday through Friday from 9:00 AM to 5:00 PM PST. </p>
        <p>Can't make it during the week? No worries! We understand that inspiration strikes at any time. Reach out to schedule a weekend appointment, and we'll ensure your woodwork aspirations are met.</p>
      </div>
    </div>
  );
}

export default ContactUs;
