import React from "react";
import captainLogo from "../imgs/Captain-G.png";
import { useNavigate } from 'react-router-dom';


const Navbar = () => {
  const navigate = useNavigate();
  const goTo = (page,event) => {
    event.preventDefault(); 
    navigate(page);
    triggerClick(page);
  };
  const triggerClick = (page) => {
    const navbarToggler = document.getElementById("navbar-toggler");
  if (navbarToggler && window.innerWidth<990 && page!="/") {
    navbarToggler.click();
  }
  };
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary" style={{background:"white", zIndex:"3"}}>
      <div className="container-fluid" style={{ position: "relative" }}>
        <button id="navbar-toggler"
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarToggle"
          aria-controls="navbarToggle"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fa-solid fa-bars"></i>
        </button>
        <a className="navbar-brand" href="#" onClick={(e) => goTo("/", e)}>
          <img src={captainLogo} alt="" style={{ width: "300px" }} />
        </a>

        <div className="collapse navbar-collapse" id="navbarToggle" style={{justifyContent:"flex-end"}}>
          <ul className="navbar-nav" style={{background:"white"}}>
            <li className="nav-item">
              <a className="nav-link" href="#" onClick={(e) => goTo("/what-we-do", e)}>
                <i className="fa-solid fa-screwdriver-wrench"></i> What we do?
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" href="/who-we-are">
                <i className="fa-solid fa-person-circle-question"></i> Who we are?
              </a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link" href="#" onClick={(e) => goTo("/contact-us", e)}>
                <i className="fa-solid fa-address-card"></i> Contact Us
              </a>
            </li>
            <li className="nav-item">
              <button className="btn-primary" style={{color:"white",borderRadius:"5px"}} onClick={(e) => goTo("/quote", e)}>Request a Quote</button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
