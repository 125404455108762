import React from 'react'
import CompWhatWeDo from './CompWhatWeDo'
import interiorImg from '../imgs/interior-new.jpeg'
import siding from '../imgs/siding-hardy.jpg'
import exterior from '../imgs/Exterior.jpeg'
import laminate from '../imgs/steps_lam.jpeg'
import flooring from '../imgs/Flooring.jpeg'
import vinyl from '../imgs/deck-vinyl.jpg'
import custom from '../imgs/custom.jpg'

const WhatWeDo = () => {
  return (<div style={{textAlign:"center",display:"flex",flexDirection:"column",alignItems:"center",padding:"10px 0px", paddingBottom: "80px"}}>
  <p className='col-lg-6'>Captain Finishing specializes in elevating both interior and exterior spaces with unparalleled excellence. From intricate interior designs to flawless exterior finishes, we bring your vision to life with precision and expertise. Explore our services to transform your spaces into captivating showcases of craftsmanship and style.</p>
    <div className='row' style={{justifyContent:"center",marginTop:"50px"}}>
        <CompWhatWeDo className="col" name="Interior Finishing" img={interiorImg} desc="Installation of Doors, Mouldings, Baseboard, Crown, Window Sill, Wooden Trims and Accent Walls"/>
        <CompWhatWeDo name="Laminate Steps" img={laminate} className="col" desc="Steps Installation from Engineered Hardwood, Vinyl and Laminate"/>
        <CompWhatWeDo name="Flooring Installation" img={flooring} className="col" desc="Laminate, Engineered Hardwood, Vinyl Planks, Sheet Vinyl and Linoleum Flooring"/>
        <CompWhatWeDo name="Exterior Finishing" img={exterior} className="col" desc="Installation of Exterior Trims, Cedar Soffits, Vinyl Soffits, Fascia Wood"/>
        <CompWhatWeDo name="Siding & Hardy Installation" img={siding} className="col" desc="Siding with Cedar, Vinyl, Hardy Planks, Hardy Board, Fluted Wall Panels"/>
        <CompWhatWeDo name="Decking" img={vinyl} className="col" desc="Sheet Vinyl, Composite Planks, Treated Wood Planks"/>
    </div>
    <div className='row' style={{justifyContent:"center"}}>
        <CompWhatWeDo className="col" name="Custom Woodwork" img={custom} desc="Solid Custom Wooden Doors, Custom Patio, Cedar Sauna Rooms"/>
    </div>
    </div>
  )
}

export default WhatWeDo