import React from "react";
import Navbar from "./Navbar";
import CarouselComp from "./CarouselComp";
import QuoteForm from "./QuoteForm";
import Footer from "./Footer";


class Home extends React.Component {
  render() {
    return (
        <div>
        
        <CarouselComp />
        
        
      </div>
    );
  }
}

export default Home;


