import React from "react";
import captainLogo from "../imgs/Captain-G.png";

const Footer = () => {
  return (
    <div id="footer" className="row" style={{ minHeight: "400px", height: "fit-content", background: "black", position: "relative", top: "-80px" , width:"104vw",overflowX:"hidden"}}>
      <div className="col-lg-5 d-flex align-items-center justify-content-center" style={{padding:"90px 0"}}>
        <img className="footer-logo" src={captainLogo} alt="Captain Logo" />
      </div>
      <div className="col-lg-3 d-flex justify-content-center" style={{flexDirection:"column", padding:"30px 0"}}>
        <p className="footer-heading">CONTACT INFO</p>
        <ul className="list-unstyled" style={{ paddingLeft: 0 }}>
        <li>
            <a href="#" className="nav-link">
              Serving Lower Mainland
            </a>
        </li>
          <li>
            <a href="#" className="nav-link">
              <i className="fas fa-map-marker-alt"></i>&nbsp;&nbsp;&nbsp; Based in New Westminster
            </a>
          </li>
          <li>
            <a href="tel:2368182065" className="nav-link">
              <i className="fas fa-phone-alt"></i>&nbsp;&nbsp;&nbsp; Arsh - (236) 818 2065
            </a>
          </li>
          <li>
            <a href="tel:7782429375" className="nav-link">
              <i className="fas fa-phone-alt"></i>&nbsp;&nbsp;&nbsp; Gurdev - (778) 242 9375
            </a>
          </li>
          <li>
            <a href="mailto:captainfinishing@gmail.com" className="nav-link">
              <i className="fas fa-envelope"></i>&nbsp;&nbsp;&nbsp; captainfinishing@gmail.com
            </a>
          </li>
        </ul>
      </div>
      <div className="col-lg-4 d-flex justify-content-center" style={{flexDirection:"column", padding:"90px 0"}} >
        <p className="footer-heading">BUSINESS HOURS</p>
        <div className="d-flex">
          <i className="fas fa-clock col-1"></i>
          <span className="col-10">
            Mon - Fri: 9:00 AM - 5:00 PM PST
            <br />
            Weekends by Appointment Only
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
