import React from 'react'

const CompWhatWeDo = ({name,img,desc}) => {
  return (
    <div className="card" style={{width: "25rem",margin:"30px"}}>
        <img src={img} className="card-img-top" alt="..."/>
        <div className="card-body">
            <h5 className="card-title">{name}</h5>
            <p>{desc}</p>
        </div>
    </div>
  )
}

export default CompWhatWeDo