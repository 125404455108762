import React from "react";
import video from '../home.mp4'

class CarouselComp extends React.Component {
  render() {
    return (
     <div style={{position:"relative", width:"100vw",overflowX:"hidden"}}>
        <div style={{opacity:"0.6",height:"100vh", position:"relative"} }>
          <video id="homeVid" src={video} autoPlay={true} controls="" muted loop style={{position:"absolute",top:"-80px"}}></video>
        </div>
      <div style={{position:"absolute",top:"40%",left:"50%",transform:"translate(-50%,-50%)",width:"max-content",textAlign:"center"}}>
        <h1>Unlocking the Beauty of Wood, One Finish at a Time.</h1>
        <h4>Where Quality Meets Craftsmanship</h4 >
      </div>
    </div>
      
    );
  }
}

export default CarouselComp;
